<template>
  <v-menu v-model="menu" offset-y rounded="b-xl" max-width="200">
    <template v-slot:activator="{ on }">
      <v-btn v-on="on" color="primary" dark fab small>
        <v-icon v-if="menu">mdi-close</v-icon>
        <v-icon v-else>mdi-menu</v-icon>
      </v-btn>
    </template>
    <v-list dark dense id="menu__list">
      <template v-if="!isKiosk">
        <v-subheader>MENU</v-subheader>
        <ListItemGroupMenu />
        <template v-if="checkCallWaiter === true">
          <v-list-item v-on:click="callWaiter" active-class="primary" exact>
            <v-list-item-icon>
              <v-icon>mdi-room-service-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="$t('callWaiter')" />
            </v-list-item-content>
          </v-list-item>
        </template>
        <v-divider />
      </template>
      <v-subheader>ACCOUNT</v-subheader>
      <ListItemGroupUser />
    </v-list>
  </v-menu>
</template>
<script>
import { getIsKioskSetting } from "@/helpers";
import { axiosInstance } from "@/plugins/axios";
import { i18n } from "@/plugins/i18n";
import { settingsDigitalLetterStoreGetters } from "../../store/modules/settingsDigitalLetter/constNames";

export default {
  name: "UserMenu",
  components: {
    ListItemGroupUser: () =>
      import("@/components/userOrLogin/ListItemGroupUser.vue"),
    ListItemGroupMenu: () =>
      import("@/components/userOrLogin/ListItemGroupMenu.vue"),
  },
  data() {
    return {
      tableId: "",
      menu: false,
    };
  },
  mounted() {
    this.getTableId();
  },
  watch: {
    $route() {
      this.getTableId();
    },
    deep: true,
  },
  computed: {
    isKiosk() {
      return getIsKioskSetting;
    },
    checkCallWaiter() {
      if (
        this.tableId &&
        this.$route.name !== "ShoppingCart" &&
        this.$route.name !== "Welcome" &&
        this.$route.name !== "ThankYou" &&
        this.$route.name !== "Bookings" &&
        this.$route.name !== "Bookings-Thanks" &&
        this.$store.getters[
          settingsDigitalLetterStoreGetters.GET_CALL_WAITER
        ] === true
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    callWaiter() {
      const headers = {
        headers: {
          Authorization: `Token ${process.env.VUE_APP_TOKEN}`,
          "Content-Type": "application/json",
          Site: process.env.VUE_APP_SITE,
          Client: "App",
          Accept: "*/*",
        },
      };
      const id = localStorage.getItem("tableId");
      axiosInstance
        .get(`/api/v1/commands/call/${id}/`, headers)
        .then(() => {
          const payload = {
            msg: i18n.t("waiterCalled"),
            success: true,
          };
          this.$store.commit("user/OPEN_SNACKBAR", payload);
        })
        .catch((e) => {
          const payload = {
            msg: e.response.data.detail
              ? e.response.data.detail
              : i18n.t("problem"),
            success: false,
          };
          this.$store.commit("user/OPEN_SNACKBAR", payload);
        });
    },
    getTableId() {
      this.tableId = localStorage.getItem("tableId");
    },
  },
};
</script>

<style lang="sass" scoped>
.v-menu__content
  top: 70px !important
  left: auto !important
  right: 20px !important
</style>
